import React from 'react';
import './styles/Button.css';

const Button = ({ children, onClick, icon, type }) => {
    const handleClick = (event) => {
        if (type === 'submit') {
            // If the button type is 'submit', find the form and submit it
            const form = event.target.closest('form');
            if (form) {
                form.requestSubmit(); // This will trigger the onSubmit event of the form
            }
        }
        if (onClick) {
            onClick(event); // If there's a click handler, call it
        }
    };

    return (
        <div className='button-shape' onClick={handleClick}>
            {icon && <i className={`fa ${icon}`}></i>} {/* Assuming FontAwesome icons */}
            <p>{children}</p>
        </div>
    );
};

export default Button;