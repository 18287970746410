import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import useStore from '../store';

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

const BASE_URLS = {
    platform: process.env.REACT_APP_API_PLATFORM_URL,
    data: process.env.REACT_APP_API_DATA_URL,
    seo: process.env.REACT_APP_API_SEO_URL
};  

const API_ROUTES = {
    // User routes (sx-platform-services)
    login: { path: '/v1/users/login', service: 'platform' },
    callback: { path: '/users/callback', service: 'platform' },
    registerUser: { path: '/users/register', service: 'platform' },
    getUsers: { path: '/users', service: 'platform' },
    getUserById: { path: id => `/users/${id}`, service: 'platform' },
    updateUserById: { path: id => `/users/${id}`, service: 'platform' },
    deleteUserById: { path: id => `/users/${id}`, service: 'platform' },

    // Client routes (sx-platform-services)
    getClients: { path: '/clients', service: 'platform' },
    getClientById: { path: id => `/clients/${id}`, service: 'platform' },
    createClient: { path: '/clients', service: 'platform' },
    updateClientById: { path: id => `/clients/${id}`, service: 'platform' },
    deleteClientById: { path: id => `/clients/${id}`, service: 'platform' },

    // Client User routes (sx-platform-services)
    getClientUsers: { path: '/clientusers', service: 'platform' },
    getClientUserById: { path: id => `/clientusers/${id}`, service: 'platform' },
    createClientUser: { path: '/clientusers', service: 'platform' },
    updateClientUserById: { path: id => `/clientusers/${id}`, service: 'platform' },
    deleteClientUserById: { path: id => `/clientusers/${id}`, service: 'platform' },

    // Source routes (sx-platform-services)
    getSources: { path: '/sources', service: 'platform' },
    getSourceById: { path: id => `/sources/${id}`, service: 'platform' },
    createSource: { path: '/sources', service: 'platform' },
    updateSourceById: { path: id => `/sources/${id}`, service: 'platform' },
    deleteSourceById: { path: id => `/sources/${id}`, service: 'platform' },

    // Destination routes (sx-platform-services)
    getDestinations: { path: '/destinations', service: 'platform' },
    getDestinationById: { path: id => `/destinations/${id}`, service: 'platform' },
    createDestination: { path: '/destinations', service: 'platform' },
    updateDestinationById: { path: id => `/destinations/${id}`, service: 'platform' },
    deleteDestinationById: { path: id => `/destinations/${id}`, service: 'platform' },

    // Client Source routes (sx-platform-services)
    getClientSources: { path: '/v1/clientsources', service: 'platform' },
    getClientSourceById: { path: id => `/clientsources/${id}`, service: 'platform' },
    createClientSource: { path: '/v1/clientsources', service: 'platform' },
    updateClientSourceById: { path: id => `/clientsources/${id}`, service: 'platform' },
    deleteClientSourceById: { path: id => `/clientsources/${id}`, service: 'platform' },

    // Client Destination routes (sx-platform-services)
    getClientDestinations: { path: '/clientdestinations', service: 'platform' },
    getClientDestinationById: { path: id => `/clientdestinations/${id}`, service: 'platform' },
    createClientDestination: { path: '/clientdestinations', service: 'platform' },
    updateClientDestinationById: { path: id => `/clientdestinations/${id}`, service: 'platform' },
    deleteClientDestinationById: { path: id => `/clientdestinations/${id}`, service: 'platform' },

    // Connection routes (sx-platform-services)
    getConnections: { path: '/connections', service: 'platform' },
    getConnectionById: { path: id => `/connections/${id}`, service: 'platform' },
    createConnection: { path: '/connections', service: 'platform' },
    updateConnectionById: { path: id => `/connections/${id}`, service: 'platform' },
    deleteConnectionById: { path: id => `/connections/${id}`, service: 'platform' },

    // sx-data-services specific route
    exchangeAuthorizationCode: { path: '/api/v1/fetch/google', service: 'data' },

    googleKeywordPlanner: { path: '/google_keywords_planner', service: 'seo' }

};

const SERVICE_CONFIG = {
    platform: {
        urlPrefix: '/services/platform'
    },
    data: {
        urlPrefix: '/services/data'
    },
    seo: {
        urlPrefix: ''  // no prefix for seo service
    }
};

export const ApiProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = useStore((state) => state.token);

    const apiRequest = async ({ action, method, data, params = {}, config = {} }) => {
        const routeConfig = API_ROUTES[action];
        if (!routeConfig) {
            console.error('API action not defined:', action);
            return;
        }

        let endpoint = routeConfig.path;
        if (typeof endpoint === 'function') {
            endpoint = endpoint(params.id);
        }

        const servicePrefix = SERVICE_CONFIG[routeConfig.service].urlPrefix;
        const url = `${BASE_URLS[routeConfig.service]}${servicePrefix}${endpoint}`;
        setIsLoading(true);

        try {
            const response = await axios({
                method,
                url,
                data,
                headers: {
                    Authorization: token ? `Bearer ${token}` : undefined,
                    ...config.headers,
                },
                ...config,
            });
            setIsLoading(false);
            return response.data;
        } catch (err) {
            setError(err);
            setIsLoading(false);
            throw err;
        }
    };

    return (
        <ApiContext.Provider value={{ apiRequest, isLoading, error }}>
            {children}
        </ApiContext.Provider>
    );
};