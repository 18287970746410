import React from 'react';
import { Routes, Route } from 'react-router-dom';
import useStore from './store';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import MainContainer from './components/MainContainer';

import Home from './pages/Home/Home'
import Login from './pages/Login/Login';
import AnalyticsCompass from './pages/AnalyticsCompass/AnalyticsCompass';
import AnalyticsIntraday from './pages/AnalyticsIntraday/AnalyticsIntraday';
import EventHubSources from './pages/EventHubSources/EventHubSources';
import PlanningKeywordPlanner from './pages/PlanningKeywordPlanner/PlanningKeywordPlanner';
import './App.css';

function App() {
  const isAuthorizedStore = useStore((state) => state.isAuthorized);
  const setAuthorization = useStore((state) => state.setAuthorization);
  const setToken = useStore((state) => state.setToken);
  const setUser = useStore((state) => state.setUser);

  React.useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    const user = sessionStorage.getItem("userData");
    
    if (token && user) {
      const isTokenValid = true;

      if (isTokenValid) {
        setToken(token);
        setUser(JSON.parse(user));
        setAuthorization(true);
      } else {
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("user");
        setAuthorization(false);
      }
    } else {
      setAuthorization(false);
    }
  }, [setAuthorization, setToken, setUser]);

  if (!isAuthorizedStore) {
    return (
      <div className="App">
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <div className='AppRight'>
        <Sidebar />
        <MainContainer>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/analytics/compass" element={<AnalyticsCompass />} />
            <Route path="/analytics/intraday" element={<AnalyticsIntraday />} />
            <Route path="/event-hub/sources" element={<EventHubSources />} />
            <Route path="/planning/keyword-planner" element={<PlanningKeywordPlanner />} />

            {/* <Route path="/settings/exit" element={<Exit />} /> */}
          </Routes>
        </MainContainer>
      </div>
    </div>
  );
}

export default App;