import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiSearch, FiBarChart2, FiTag, FiDollarSign, FiTrendingUp, FiDownload, FiArrowUp, FiArrowDown, FiChevronDown, FiLayers, FiGrid } from 'react-icons/fi';
import useStore from '../../store';
import './styles/PlanningKeywordPlanner.css';
import PageHeader from '../../components/PageHeader/PageHeader';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { useApi } from '../../contexts/ApiProvider';

const PlanningKeywordPlanner = () => {
    const { apiRequest, isLoading } = useApi();
    const setKeywordPlannerResults = useStore(state => state.setKeywordPlannerResults);
    const savedResults = useStore(state => state.keywordPlannerResults);
    
    const [formData, setFormData] = useState({
        search: '',
        competition: 'hard',
        branded: 'yes',
        industry: [], // Changed from '' to []
        location: '',
        language: ['en'] // Changed from 'en' to ['en']
    });
    const [keywordData, setKeywordData] = useState(savedResults || null);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'desc' });
    const [isFormCollapsed, setIsFormCollapsed] = useState(false);

    useEffect(() => {
        // Collapse form when results are shown
        if (keywordData) {
            setIsFormCollapsed(true);
        }
    }, [keywordData]);

    const competitionOptions = [
        { value: 'hard', label: 'High Competition' },
        { value: 'medium', label: 'Medium Competition' },
        { value: 'easy', label: 'Low Competition' }
    ];

    const brandedOptions = [
        { value: 'yes', label: 'Branded Keywords' },
        { value: 'no', label: 'Non-Branded Keywords' }
    ];

    const industryOptions = [
        { value: 'ecommerce', label: 'E-commerce' },
        { value: 'tech', label: 'Technology' },
        // Add more options
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSelectChange = (option, { name }) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: option ? (Array.isArray(option) ? option.map(o => o.value) : option.value) : (name === 'industry' || name === 'language' ? [] : '')
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiRequest({
                action: 'googleKeywordPlanner',
                method: 'post',
                data: {
                    trigger: formData
                }
            });
            setKeywordData(response);
            setKeywordPlannerResults(response);
            setError(null);
        } catch (err) {
            setError('Failed to fetch keyword data. Please try again.');
        }
    };

    const calculateStats = (data) => {
        if (!data) return { totalKeywords: 0, avgCPC: 0, totalVolume: 0, avgCompetition: 0 };

        // Flatten all keywords from all channels and stages
        const allKeywords = Object.entries(data).flatMap(([stage, channels]) => 
            Object.values(channels).flat()
        ).filter(Boolean);

        if (allKeywords.length === 0) {
            return { totalKeywords: 0, avgCPC: 0, totalVolume: 0, avgCompetition: 0 };
        }
        return {
            totalKeywords: allKeywords.length,
            avgCPC: allKeywords.reduce((acc, kw) => acc + (kw.cpc || 0), 0) / allKeywords.length,
            totalVolume: allKeywords.reduce((acc, kw) => acc + (kw.search_volume || 0), 0),
            avgCompetition: (allKeywords.reduce((acc, kw) => acc + (kw.cpc || 0), 0) / allKeywords.length) * 
                   allKeywords.reduce((acc, kw) => acc + (kw.search_volume || 0), 0),
        };
        };

    const handleSort = (key) => {
        setSortConfig(prevSort => ({
            key,
            direction: prevSort.key === key && prevSort.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const sortData = (data, sortConfig) => {
        if (!sortConfig.key) return data;

        return Object.entries(data).reduce((acc, [stage, channels]) => {
            acc[stage] = Object.entries(channels).reduce((channelAcc, [channel, keywords]) => {
                channelAcc[channel] = [...keywords].sort((a, b) => {
                    if (sortConfig.key === 'keyword') {
                        return sortConfig.direction === 'asc'
                            ? a.keyword.localeCompare(b.keyword)
                            : b.keyword.localeCompare(a.keyword);
                    }
                    const aValue = Number(a[sortConfig.key]) || 0;
                    const bValue = Number(b[sortConfig.key]) || 0;
                    return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
                });
                return channelAcc;
            }, {});
            return acc;
        }, {});
    };

    const sortedData = sortConfig.key ? sortData(keywordData, sortConfig) : keywordData;

    const handleDownload = () => {
        if (!keywordData) return;
                const allKeywords = Object.entries(keywordData).flatMap(([stage, channels]) => 
            Object.values(channels).flat().map(kw => ({
                ...kw,
                stage,
                channel: Object.keys(channels).find(key => channels[key].includes(kw))
            }))
        );
        
        const csvContent = allKeywords.map(kw => 
            `${kw.keyword},${kw.stage},${kw.channel},${kw.search_volume},${kw.cpc},${kw.competition_index}`
        ).join('\n');
        
        const blob = new Blob([`Keyword,Stage,Channel,Search Volume,CPC,Competition\n${csvContent}`], 
            { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'keyword_results.csv';
        link.click();
    };

    const tableHeaders = [
        { key: 'keyword', label: 'Keyword', icon: <FiTag /> },
        { key: 'channel', label: 'Channel', icon: <FiGrid /> },
        { key: 'search_volume', label: 'Search Volume', icon: <FiBarChart2 /> },
        { key: 'cpc', label: 'CPC', icon: <FiDollarSign /> },
        { key: 'competition_index', label: 'Competition', icon: <FiTrendingUp /> }
    ];

    const renderKeywordTable = (data) => {
        if (!data) return null;

        // Group keywords by stage and channel
        const groupedData = Object.entries(data).reduce((acc, [stage, channels]) => {
            const channelGroups = Object.entries(channels).reduce((channelAcc, [channel, keywords]) => {
                channelAcc[channel] = keywords.map(kw => ({...kw, channel}));
                return channelAcc;
            }, {});

            acc[stage] = channelGroups;
            return acc;
        }, {});

        return (
            <div className="results-section">
                <div className="results-table-header">
                    <div className="header-left">
                        <h3>Keyword Results</h3>
                    </div>
                    <div className="table-actions">
                        <button onClick={handleDownload} className="download-btn">
                            <FiDownload /> Download CSV
                        </button>
                    </div>
                </div>
                <table className="results-table">
                    <thead>
                        <tr>
                            {tableHeaders.map(({ key, label, icon }) => (
                                <th key={key} onClick={() => handleSort(key)}>
                                    {icon}
                                    <span>{label}</span>
                                    {sortConfig.key === key && (
                                        <span className="sort-icon">
                                            {sortConfig.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(groupedData).map(([stage, channelGroups]) => (
                            <React.Fragment key={stage}>
                                <tr className="stage-header">
                                    <td colSpan="5">
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                            <FiLayers />
                                            {stage.toUpperCase()}
                                            <span className="badge badge-stage">
                                                {Object.values(channelGroups).flat().length} KEYWORDS
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                {Object.entries(channelGroups).map(([channel, keywords]) => (
                                    <React.Fragment key={`${stage}-${channel}`}>
                                        <tr className="channel-header">
                                            <td colSpan="5">
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                                    <FiGrid />
                                                    {channel.toUpperCase()}
                                                    <span className="badge badge-channel">
                                                        {keywords.length} KEYWORDS
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        {keywords.map((keyword, index) => (
                                            <tr key={`${stage}-${channel}-${index}`}>
                                                <td>{keyword.keyword || 'N/A'}</td>
                                                <td>{keyword.channel}</td>
                                                <td>{(keyword.search_volume || 0).toLocaleString()}</td>
                                                <td>${(keyword.cpc || 0).toFixed(2)}</td>
                                                <td>
                                                    <span className={`competition-badge competition-${getCompetitionLevel(keyword.competition_index)}`}>
                                                        {getCompetitionLevel(keyword.competition_index).toUpperCase()}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const getCompetitionLevel = (index) => {
        if (!index) return 'unknown';
        if (index >= 0.66) return 'high';
        if (index >= 0.33) return 'medium';
        return 'low';
    };

    const toggleForm = () => {
        setIsFormCollapsed(!isFormCollapsed);
    };

    return (
        <>
            <LoaderOverlay isLoading={isLoading} />
            <PageHeader title="Keyword Planner">
                Discover high-performing keywords for your marketing campaigns
            </PageHeader>

            <div className="keyword-planner-container">
                <div className="search-section">
                    <div className="search-section-header" onClick={toggleForm}>
                        <h3 className="section-title">
                            Search Keywords
                            <FiChevronDown className={`collapse-icon ${isFormCollapsed ? 'collapsed' : ''}`} />
                        </h3>
                    </div>
                    <div className={`form-container ${isFormCollapsed ? 'collapsed' : ''}`}>
                        <form onSubmit={handleSubmit}>
                            <div className="search-section">
                                <h3 className="section-title">Main Keyword</h3>
                                <div className="search-box">
                                    <input
                                        type="text"
                                        name="search"
                                        value={formData.search}
                                        onChange={handleChange}
                                        placeholder="Enter your main keyword or phrase"
                                        className="main-search-input"
                                    />
                                </div>
                            </div>

                            <div className="filter-section">
                                <h3 className="section-title">Keyword Filters</h3>
                                <div className="filter-grid">
                                    <div className="filter-item">
                                        <label>Industry</label>
                                        <Select
                                            isMulti // Added for multi-select
                                            options={industryOptions}
                                            onChange={(option) => handleSelectChange(option, { name: 'industry' })}
                                            value={industryOptions.filter(option => formData.industry.includes(option.value))}
                                            className="select-modern"
                                            classNamePrefix="select"
                                            placeholder="Select industries..."
                                        />
                                    </div>
                                    <div className="filter-item">
                                        <label>Competition Level</label>
                                        <Select
                                            options={competitionOptions}
                                            onChange={(option) => handleSelectChange(option, { name: 'competition' })}
                                            value={competitionOptions.find(option => option.value === formData.competition)}
                                            className="select-modern"
                                            classNamePrefix="select"
                                            placeholder="Select competition level..."
                                        />
                                    </div>
                                    <div className="filter-item">
                                        <label>Branded Terms</label>
                                        <Select
                                            options={brandedOptions}
                                            onChange={(option) => handleSelectChange(option, { name: 'branded' })}
                                            value={brandedOptions.find(option => option.value === formData.branded)}
                                            className="select-modern"
                                            classNamePrefix="select"
                                            placeholder="Select branded option..."
                                        />
                                    </div>
                                    <div className="filter-item">
                                        <label>Language</label>
                                        <Select
                                            isMulti // Added for multi-select
                                            options={[
                                                { value: 'en', label: 'English' },
                                                { value: 'es', label: 'Spanish' },
                                                { value: 'pt', label: 'Portuguese' }
                                            ]}
                                            onChange={(option) => handleSelectChange(option, { name: 'language' })}
                                            value={formData.language.map(lang => ({ value: lang, label: lang.toUpperCase() }))}
                                            className="select-modern"
                                            classNamePrefix="select"
                                            placeholder="Select languages..."
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="submit-section">
                                <button type="submit" className="submit-button">
                                    <FiSearch /> Generate Keywords
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                {error && <div className="error-message">{error}</div>}
            
                {keywordData && (
                    <div className="results-container">
                        <div className="stats-dashboard">
                            <div className="stat-card">
                                <div className="stat-header">
                                    <div>
                                        <p className="stat-title">Total Keywords</p>
                                        <h3 className="stat-value">{calculateStats(keywordData).totalKeywords.toLocaleString()}</h3>
                                    </div>
                                    <div className="stat-icon-wrapper">
                                        <FiTag className="stat-icon" />
                                    </div>
                                </div>
                                <p className="stat-description">Total number of keywords found</p>
                            </div>

                            <div className="stat-card">
                                <div className="stat-header">
                                    <div>
                                        <p className="stat-title">Average CPC</p>
                                        <h3 className="stat-value">${calculateStats(keywordData).avgCPC.toFixed(2)}</h3>
                                    </div>
                                    <div className="stat-icon-wrapper">
                                        <FiDollarSign className="stat-icon" />
                                    </div>
                                </div>
                                <p className="stat-description">Average cost per click</p>
                            </div>

                            <div className="stat-card">
                                <div className="stat-header">
                                    <div>
                                        <p className="stat-title">Total Search Volume</p>
                                        <h3 className="stat-value">{calculateStats(keywordData).totalVolume.toLocaleString()}</h3>
                                    </div>
                                    <div className="stat-icon-wrapper">
                                        <FiBarChart2 className="stat-icon" />
                                    </div>
                                </div>
                                <p className="stat-description">Monthly search volume</p>
                            </div>

                            <div className="stat-card">
                                <div className="stat-header">
                                    <div>
                                        <p className="stat-title">Market Coverage</p>
                                        <h3 className="stat-value">${(calculateStats(keywordData).avgCompetition).toFixed(2)}</h3>
                                    </div>
                                    <div className="stat-icon-wrapper">
                                        <FiTrendingUp className="stat-icon" />
                                    </div>
                                </div>
                                <p className="stat-description">Average Marget Coverage</p>
                            </div>
                        </div>
                        {renderKeywordTable(sortedData)}
                    </div>
                )}
            </div>
        </>
    );
};

export default PlanningKeywordPlanner;