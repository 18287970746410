import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { ApiProvider } from './contexts/ApiProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Root() {

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  return (
    <React.StrictMode>
    <ApiProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApiProvider>
   </React.StrictMode>
  );
}

root.render(
  <Root/>
);