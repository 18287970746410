import React from 'react';
import './styles/Sidebar.css';
import HeaderButton from '../HeaderButton';
import useStore from '../../store';

const Sidebar = () => {

    const userData = useStore((state) => state.user);

    return (
      <div className="sidebar">
        <div className="sidebar-left">
        </div>
        <div className="sidebar-right">
          <HeaderButton icon={'faUser'} subtitle={userData.user.Email}>{userData.user.FirstName} {userData.user.LastName}</HeaderButton>
        </div>
      </div>
    );
  };


export default Sidebar;