import React from 'react';
import './styles/Home.css'
import '../../pages/styles/pages.css'
import { FiDollarSign, FiTrendingUp, FiActivity, FiTarget } from 'react-icons/fi';
import CardBox from '../../components/CardBox/CardBox';
import PageHeader from '../../components/PageHeader/PageHeader'; 
import useStore from '../../store';
import CampaignMetrics from '../../components/CampaignMetrics/CampaignMetrics';

const Home = () => {
  const userInfo = useStore((state) => state.user);

  const metrics = [
    {
      title: "Total Revenue",
      icon: FiDollarSign,
      value: "$12,345",
      trend: 12.5,
      description: "Total revenue this month"
    },
    {
      title: "Active Campaigns",
      icon: FiActivity,
      value: "24",
      trend: 5.2,
      description: "Currently running campaigns"
    },
    {
      title: "Conversion Rate",
      icon: FiTrendingUp,
      value: "3.2%",
      trend: -2.1,
      description: "Average conversion rate"
    },
    {
      title: "Total Reach",
      icon: FiTarget,
      value: "45.2K",
      trend: 8.1,
      description: "Users reached this month"
    }
  ];

  return (
    <div className='content'>
      <PageHeader title={`Welcome back, ${userInfo.user.FirstName}!`}>
        Your campaign performance overview
      </PageHeader>

      <div className='metrics-grid'>
        {metrics.map((metric, index) => (
          <div key={index} className='metric-card'>
            <div className='metric-header'>
              <div className='metric-info'>
                <p className='metric-title'>{metric.title}</p>
                <h3 className='metric-value'>{metric.value}</h3>
              </div>
              <div className='metric-icon'>
                <metric.icon size={24} />
              </div>
            </div>
            <p className='metric-description'>{metric.description}</p>
            <div className='metric-trend'>
              <span className={metric.trend > 0 ? 'trend-up' : 'trend-down'}>
                {metric.trend > 0 ? '↑' : '↓'} {Math.abs(metric.trend)}%
              </span>
              <span className='trend-label'>vs last month</span>
            </div>
          </div>
        ))}
      </div>

   
      <CampaignMetrics />
    </div>
  );
};

export default Home;