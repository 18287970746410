import React, { useState, useEffect } from 'react';
import './styles/EventHubSources.css'
import '../../pages/styles/pages.css'
import PageHeader from '../../components/PageHeader/PageHeader'; 
import Button from '../../components/Button/Button'; 
import FallbackMessage from '../../components/FallbackMessage/FallbackMessage';
import EventHubSourceSidePanel from '../../components/EventHubSourceSidePanel/EventHubSourceSidePanel'; 
import useStore from '../../store';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { useApi } from '../../contexts/ApiProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const SidePanel = ({ isOpen, togglePanel }) => (
    <div className={`side-panel ${isOpen ? 'open' : ''}`}>
      <EventHubSourceSidePanel togglePanel={togglePanel}/>
    </div>
);

const FallbackView = () => (
    <FallbackMessage icon={'database'} subtext={'Add a source to start managing your data'}>
            No sources in this account
    </FallbackMessage>
);

const TableRow = ({ item }) => {
    const getSourceIcon = (source) => {
        switch(source) {
            case 'Google Ads':
                return faGoogle;
            case 'Facebook':
                return faFacebookF;
            default:
                return faGoogle;
        }
    };

    return (
        <tr>
            <td>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <FontAwesomeIcon icon={getSourceIcon('Google Ads')} />
                    Google Ads
                </div>
            </td>
            <td>{item.AccountName}</td>
            <td>{item.AccountID}</td>
            <td>
                <div className='status'>
                    <FontAwesomeIcon icon={faCircleCheck} />
                    Connected
                </div>
            </td>
        </tr>
    );
};

const SourcesList = ({ data }) => (
    <div className="sources-list-container">
        <table>
            <thead>
                <tr>
                    <th>Source</th>
                    <th>Account Name</th>
                    <th>Account ID</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <TableRow key={item.ClientSourceID} item={item} />
                ))}
            </tbody>
        </table>
    </div>
);

const EventHubSources = () => { 
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [sourcesData, setSourcesData] = useState([]);
    const { apiRequest, isLoading } = useApi();
    const setAccounts = useStore((state) => state.setAccounts);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest({
                    action: 'getClientSources',
                    method: 'get',
                });
                setSourcesData(response);
                setAccounts(response)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const togglePanel = () => {
        setPanelOpen(prevState => !prevState);
    };

    return (
        <>
        <LoaderOverlay isLoading={isLoading} />
          <div className='header-compartiment'>
            <PageHeader title={"Sources"}>Connect various sources of data for enhanced reporting.</PageHeader>
            <Button icon={'faPlus'} onClick={togglePanel}>Add Source</Button>
          </div>
  
          {sourcesData.length > 0 ? (
            <SourcesList data={sourcesData} />
          ) : (
            <FallbackView />
          )}
  
          <div className={`overlay ${isPanelOpen ? 'show' : ''}`} onClick={togglePanel} />
  
          <SidePanel isOpen={isPanelOpen} togglePanel={togglePanel} />
        </>
    );
};

export default EventHubSources;