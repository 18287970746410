import React from 'react';
import './styles/CardBox.css';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardBox = ({ title, children, icon }) => {
    const IconComponent = Icons[icon];

    return (
        <div className='box'>
            <div className='box-header'>
            {IconComponent && <FontAwesomeIcon icon={IconComponent} className="icon" />}
            <h2>{title}</h2>
            </div>
            <p>{children}</p>
        </div>
    );
};

export default CardBox;