import React from 'react'
import './styles/FallbackMessage.css';
import SVGIcons from '../SVGIcons';

const FallbackMessage = ({icon, subtext, children}) => {

    return (
        <div className="no-sources-container">
        <SVGIcons name={icon} />
        <h3>{children}</h3>
        <p>{subtext}</p>
      </div>
    )
}

export default FallbackMessage