import React from 'react';
import './styles/PageHeader.css';

const PageHeader = ({ title, children }) => (
  <div className='page-header'>
  <div className='title-header'><h1>{title}</h1>
  <div className='subtitle-header'>
    <p>{children}</p>
  </div>
  </div>
</div>
);

export default PageHeader;