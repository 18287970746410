import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

const LoaderOverlay = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', 
            zIndex: 1000
        }}>
            <MoonLoader size={50} color="#05B24F" />
        </div>
    );
};

export default LoaderOverlay;
