import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { GoogleLogin } from '@react-oauth/google';
import { useApi } from '../../contexts/ApiProvider';
import LoaderOverlay from '../LoaderOverlay/LoaderOverlay';
import Button from '../Button/Button';
import './FormStyle.css';

const ConfirmationModal = ({ onClose, onConfirm }) => (
  <div className="confirmation-overlay">
    <div className="confirmation-modal">
      <h4>Are you sure?</h4>
      <p>All unsaved progress will be lost.</p>
      <div className="modal-actions">
        <button onClick={onConfirm} className="btn-confirm">Confirm</button>
        <button onClick={onClose} className="btn-cancel">Cancel</button>
      </div>
    </div>
  </div>
);

const FacebookAds = ({ togglePanel, goBack }) => {
  const importDataOptions = [
    { value: '7', label: '7 days' },
    { value: '30', label: '30 days' },
    { value: '60', label: '60 days' },
    { value: '90', label: '90 days' },
    { value: '180', label: '180 days' },
    { value: '1y', label: '1 year' },
    { value: '2y', label: '2 years' },
  ];

  const { apiRequest, isLoading } = useApi();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [importPastData, setImportPastData] = useState('');
  const [formErrors, setFormErrors] = useState({ accountError: false, importDataError: false });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmResetSidePanel = () => {
    goBack();
    togglePanel();
  };

  const resetSidePanel = () => {
    setShowConfirmation(true);
  };

  const handleSuccess = (response) => {
    console.log('Login Successful', response);
    const fetchedAccounts = [
      { value: 'account1', label: 'Account 1' },
      { value: 'account2', label: 'Account 2' }
    ];
    setAccounts(fetchedAccounts);
  };

  const handleError = () => {
    console.log('Login Failed');
  };

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption);
    setFormErrors(prev => ({ ...prev, accountError: !selectedOption }));
  };

  const handleImportDataChange = (selected) => {
    setImportPastData(selected.value);
    setFormErrors(prev => ({ ...prev, importDataError: !selected }));
  };

  const validateForm = () => {
    const errors = {
      accountError: !selectedAccount,
      importDataError: !importPastData
    };
    setFormErrors(errors);
    return !errors.accountError && !errors.importDataError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const formData = {
          accounts: selectedAccount,
          importData: importPastData
        };
        console.log('Submitting:', formData);
        const response = await apiRequest({
          action: 'fetchFacebookAdsData',
          method: 'post',
          data: formData
        });
        console.log('Submission successful', response);
        togglePanel(); // Close panel
        window.location.reload(); // Re-render the page
      } catch (error) {
        console.error('Submission failed', error);
      }
    } else {
      console.error('Please fill all required fields');
    }
  };

  return (
    <>
      <LoaderOverlay isLoading={isLoading} />
      <div className="header-dock">
        <div className="close-side-form">
          <FontAwesomeIcon icon={faAngleLeft} onClick={goBack} />
          <FontAwesomeIcon icon={faClose} onClick={resetSidePanel} />
        </div>
        <div className="header-side">
          <h2>Facebook Ads</h2>
        </div>
        <p>Authenticate using OAuth and fetch data from your authorized Facebook Ads accounts securely.</p>
        {/* <GoogleLogin
          buttonText="Login with Google"
          onSuccess={handleSuccess}
          onFailure={handleError}
          cookiePolicy="single_host_origin"
          style={{ width: '200px', justifyContent: 'center' }}
          size="large"
          shape="pill"
        /> */}
      </div>
      <div className="form">
        {accounts.length > 0 && (
          <>
            <label htmlFor="account-select">Ad Accounts:</label>
            <Select
              id="account-select"
              options={accounts}
              onChange={handleAccountChange}
              value={selectedAccount}
              className="account-select"
              isMulti={true}
              aria-required="true"
            />
            {formErrors.accountError && <p className="error">Please select at least one account.</p>}
            <form onSubmit={handleSubmit}>
              <label htmlFor="FacebookAdsCustomerId">Facebook Ads Customer ID:</label>
              <input type="text" name="FacebookAdsCustomerId" required />
              <label htmlFor="import-data">Import past data:</label>
              <Select
                id="import-data"
                options={importDataOptions}
                onChange={handleImportDataChange}
                value={importDataOptions.find(option => option.value === importPastData)}
                className="import-select"
                aria-required="true"
              />
              {formErrors.importDataError && <p className="error">Please select a data import range.</p>}
              <Button className="btn-submit" type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Connect'}
              </Button>
            </form>
          </>
        )}
      </div>
      {showConfirmation && (
        <ConfirmationModal
          onClose={() => setShowConfirmation(false)}
          onConfirm={confirmResetSidePanel}
        />
      )}
    </>
  );
};

export default FacebookAds;