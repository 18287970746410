import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import GoogleAds from '../../components/EventHubSourceForms/GoogleAds'; 
import FacebookAds from '../../components/EventHubSourceForms/FacebookAds'; 

import { faClose } from '@fortawesome/free-solid-svg-icons';

import './styles/EventHubSourceSidePanel.css';

const dataSources = [
    { name: "Google Ads", icon: faGoogle, status: "Available", component: GoogleAds },
    { name: "Facebook Ads", icon: faFacebook, status: "Coming Soon", component: FacebookAds },
];

const DataSourceItem = ({ name, icon, status, onSelect }) => (
    <div className="data-source-item" onClick={onSelect}>
        {icon && <FontAwesomeIcon icon={icon} size="2x" className="data-source-icon" />}
        <div className="data-source-details">
            <div className="data-source-name">{name}</div>
        </div>
        <div className={`data-source-status ${status.toLowerCase().replace(' ', '-')}`}>
            {status}
        </div>
    </div>
);

const EventHubSourceSidePanel = ({togglePanel}) => {
    const [selectedFormComponent, setSelectedFormComponent] = useState(null);
    const [formProps, setFormProps] = useState({});

    const handleSelectSource = (component, additionalProps) => {
        setSelectedFormComponent(() => component);
        setFormProps({ ...additionalProps, togglePanel });
    };

    const handleGoBack = () => {
        setSelectedFormComponent(null);
    };

    if (selectedFormComponent) {
        const FormComponent = selectedFormComponent;
        return <FormComponent goBack={handleGoBack} togglePanel={togglePanel} />;
    }

    return (
        <>
         <div className='close-side' onClick={togglePanel}>
            <FontAwesomeIcon icon={faClose} />
        </div>
        <div className='header-side'>
        <h2>Add Source</h2>
        </div>
        <p>Choose one of the available data sources for start syncing your data.</p>
            <div className="data-sources">
                {dataSources.map((source, index) => (
                    <DataSourceItem
                        key={index}
                        name={source.name}
                        icon={source.icon}
                        status={source.status}
                        onSelect={() => handleSelectSource(source.component, { 
                            onFormSubmit: (e) => {
                                e.preventDefault();
                                console.log('Form Submitted', new FormData(e.target));
                            }
                        })}
                    />
                ))}
            </div>
    </>
    );

};

export default EventHubSourceSidePanel;