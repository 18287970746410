import React from 'react';
import './styles/AnalyticsCompass.css'
import '../../pages/styles/pages.css'
import PageHeader from '../../components/PageHeader/PageHeader'; 
import FallbackMessage from '../../components/FallbackMessage/FallbackMessage'; 

const FallbackView = () => (
  <FallbackMessage icon={'report'} subtext={'Add at least one network account for unlock this report.'}>
      No sources in this account
  </FallbackMessage>
);

const AnalyticsCompass = () => {
  return (
    <>
    <PageHeader 
      title={"Compass"}
    >
      Unlock insights about your business with our detailed daily reports.
    </PageHeader>

    <FallbackView />
    </>

  );
};

export default AnalyticsCompass