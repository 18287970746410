import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ onClose, onConfirm }) => (
  <div className="confirmation-overlay">
    <div className="confirmation-modal">
      <h4>Are you sure?</h4>
      <p>All unsaved progress will be lost.</p>
      <div className="modal-actions">
        <button onClick={onConfirm} className="btn-confirm">Confirm</button>
        <button onClick={onClose} className="btn-cancel">Cancel</button>
      </div>
    </div>
  </div>
);

export default ConfirmationModal;