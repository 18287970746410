import React from 'react';
import './CampaignMetrics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faPause } from '@fortawesome/free-solid-svg-icons';

const mockCampaigns = [
  {
    campaignName: "Summer Sale 2023",
    channels: ["Facebook", "Google Ads", "Instagram"],
    budget: "$5,000",
    performance: [
      { channel: "Facebook", conversionRate: "2.4%" },
      { channel: "Google Ads", conversionRate: "3.1%" },
      { channel: "Instagram", conversionRate: "2.8%" }
    ],
    status: "active"
  },
  {
    campaignName: "Holiday Special",
    channels: ["Facebook", "Google Ads"],
    budget: "$3,500",
    performance: [
      { channel: "Facebook", conversionRate: "2.8%" },
      { channel: "Google Ads", conversionRate: "3.5%" }
    ],
    status: "active"
  },
  {
    campaignName: "Spring Collection",
    channels: ["Instagram", "Facebook"],
    budget: "$2,800",
    performance: [
      { channel: "Instagram", conversionRate: "2.1%" },
      { channel: "Facebook", conversionRate: "1.9%" }
    ],
    status: "paused"
  }
];

const CampaignMetrics = () => {
  return (
    <div className="campaign-metrics">
      <div className="metrics-header">
        <h2 className="section-title">Active Campaigns</h2>
        <button className="view-all-btn">View All Campaigns</button>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Channels</th>
              <th>Budget</th>
              <th>Performance</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {mockCampaigns.map((campaign, index) => (
              <tr key={index} className="campaign-row">
                <td>
                  <div className="campaign-name">
                    {campaign.campaignName}
                    <span className="campaign-actions">
                      <button className="action-btn">Edit</button>
                      <button className="action-btn">View</button>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="channel-tags">
                    {campaign.channels.map((channel, idx) => (
                      <span key={idx} className="channel-tag">{channel}</span>
                    ))}
                  </div>
                </td>
                <td className="budget-cell">{campaign.budget}</td>
                <td>
                  <div className="performance-metrics">
                    {campaign.performance.map((perf, idx) => (
                      <div key={idx} className="performance-item">
                        <span className="channel-name">{perf.channel}:</span>
                        <span className="conversion-rate">{perf.conversionRate}</span>
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <span className={`status-badge ${campaign.status}`}>
                    <FontAwesomeIcon icon={campaign.status === 'active' ? faCirclePlay : faPause} />
                    {campaign.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CampaignMetrics;