import React, { useEffect, useCallback } from 'react';
import { useApi } from '../../contexts/ApiProvider';

const OAuthFlow = ({ clientId, redirectUri, scopes, onTokenReceived }) => {
  const { apiRequest } = useApi();

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scopes)}&access_type=offline&prompt=consent&include_granted_scopes=true`;

  const initiateOAuthFlow = useCallback(() => {
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const oauthWindow = window.open(
      authUrl,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    const oauthInterval = setInterval(() => {
      try {
        if (oauthWindow.location.href.indexOf(redirectUri) !== -1) {
          clearInterval(oauthInterval);

          const urlParams = new URLSearchParams(oauthWindow.location.search);
          const code = urlParams.get('code');

          if (code) {
            window.postMessage({ code }, window.location.origin);
            oauthWindow.close();
          }
        }
      } catch (error) {

      }
    }, 100);
  }, [authUrl, redirectUri]);

  const handleOAuthMessage = useCallback(
    async (event) => {
      if (event.origin === window.location.origin && event.data.code) {
        try {
          const response = await apiRequest({
            action: 'exchangeAuthorizationCode',
            method: 'post',
            data: { code: event.data.code },
          });

          onTokenReceived(response);
        } catch (error) {
          console.error('Failed to exchange authorization code:', error);
        }
      }
    },
    [apiRequest, onTokenReceived]
  );

  useEffect(() => {
    window.addEventListener('message', handleOAuthMessage);
    return () => window.removeEventListener('message', handleOAuthMessage);
  }, [handleOAuthMessage]);

  return (
    <button onClick={initiateOAuthFlow} className="google-signin-button">
      Sign in with Google
    </button>
  );
};

export default OAuthFlow;