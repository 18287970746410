// GoogleAds.js
import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { useApi } from '../../contexts/ApiProvider';
import LoaderOverlay from '../LoaderOverlay/LoaderOverlay';
import Button from '../Button/Button';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import GoogleOAuthFlow from '../OAuthFlow/GadsOAuthFlow';
import { importDataOptions } from '../../assets/constants/DataOptions';
import useStore from '../../store';
import './FormStyle.css';

const GoogleAds = ({ togglePanel, goBack }) => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
  const SCOPE = process.env.REACT_APP_OAUTH_SCOPE;

  const { apiRequest, isLoading } = useApi();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [importPastData, setImportPastData] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [formErrors, setFormErrors] = useState({ accountError: false, importDataError: false });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fetchedAccounts, setFetchedAccounts] = useState([]);

  const userData = useStore((state) => state.user);

  const handleTokenReceived = useCallback((response) => {
    const accounts = response.accounts
    .filter((account) => account.manager === false)
    .map((account) => ({
      value: account.id,
      label: account.descriptive_name,
      currency_code: account.currency_code,
      time_zone: account.time_zone,
    }));
    setFetchedAccounts(accounts);
    setRefreshToken(response.refresh_token);
  }, []);

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption);
    setFormErrors((prev) => ({ ...prev, accountError: !selectedOption }));
  };

  const handleImportDataChange = (selected) => {
    setImportPastData(selected.value);
    setFormErrors((prev) => ({ ...prev, importDataError: !selected }));
  };

  const validateForm = () => {
    const errors = {
      accountError: !selectedAccount,
      importDataError: !importPastData,
    };
    setFormErrors(errors);
    return !errors.accountError && !errors.importDataError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const selectedAccountsData = selectedAccount.map((account) => ({
          AccountName: account.label,
          AccountID: account.value,
          ExtraConfig: JSON.stringify({
            currency: account.currency_code,
            time_zone: account.time_zone,
          }),
        }));
  
        const formData = {
          accounts: selectedAccountsData,
          importData: importPastData,
          clientId: userData.clients[0].ClientID,
          sourceId: 1,
          AuthToken: refreshToken
        };
  
        await apiRequest({
          action: 'createClientSource',
          method: 'post',
          data: formData,
        });
        togglePanel();
        window.location.reload();

      } catch (error) {
        console.error('Submission failed', error);
      }
    } else {
      console.error('Please fill all required fields');
    }
  };


  const confirmResetSidePanel = useCallback(() => {
    goBack();
    togglePanel();
  }, [goBack, togglePanel]);

  return (
    <>
      <LoaderOverlay isLoading={isLoading} />
      <div className="header-dock">
        <div className="close-side-form">
          <FontAwesomeIcon icon={faAngleLeft} onClick={goBack} />
          <FontAwesomeIcon icon={faClose} onClick={() => setShowConfirmation(true)} />
        </div>
        <div className="header-side">
          <h2>Google Ads</h2>
        </div>
        <p>Authenticate using OAuth and fetch data from your authorized Google Ads accounts securely.</p>
        <GoogleOAuthFlow 
          clientId={CLIENT_ID} 
          redirectUri={REDIRECT_URI} 
          scopes={SCOPE} 
          onTokenReceived={handleTokenReceived} 
        />
      </div>
      <div className="form">
        {fetchedAccounts.length > 0 && (
          <>
            <label htmlFor="account-select">Ad Accounts:</label>
            <Select
              id="account-select"
              options={fetchedAccounts}
              onChange={handleAccountChange}
              value={selectedAccount}
              className="account-select"
              isMulti={true}
              aria-required="true"
            />
            {formErrors.accountError && <p className="error">Please select at least one account.</p>}
            <form onSubmit={handleSubmit}>
              <label htmlFor="import-data">Import past data:</label>
              <Select
                id="import-data"
                options={importDataOptions}
                onChange={handleImportDataChange}
                value={importDataOptions.find((option) => option.value === importPastData)}
                className="import-select"
                aria-required="true"
              />
              {formErrors.importDataError && <p className="error">Please select a data import range.</p>}
              <Button className="btn-submit" type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Connect'}
              </Button>
            </form>
          </>
        )}
      </div>
      {showConfirmation && (
        <ConfirmationModal
          onClose={() => setShowConfirmation(false)}
          onConfirm={confirmResetSidePanel}
        />
      )}
    </>
  );
};

export default GoogleAds;