// src/store.js
import create from 'zustand';

const useStore = create((set) => ({
    isAuthorized: false,
    token: null,
    user: null,
    accounts: [],
    keywordPlannerResults: null,
    keywordPlannerForm: null,

    setAuthorization: (isAuthorized) => set({ isAuthorized }),
    setToken: (token) => set({ token }),
    setUser: (user) => set({ user }),
    setAccounts: (accounts) => set({ accounts }),
    setKeywordPlannerResults: (results) => set({ keywordPlannerResults: results }),
    setKeywordPlannerForm: (formData) => set({ keywordPlannerForm: formData }),
}));

export default useStore;